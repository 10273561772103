<template>
  <div class="app-content app-container">
      <el-descriptions class="padding-box" title="" :column="1">
        <el-descriptions-item label="期刊">{{content.period}}</el-descriptions-item>
        <el-descriptions-item label="问卷ID">{{content.sourceId}}</el-descriptions-item>
        <el-descriptions-item label="推送返回XML">{{content.uploadXml}}</el-descriptions-item>
        <el-descriptions-item label="查询返回XML">
          {{content.downloadXml}}
        </el-descriptions-item>
        <el-descriptions-item label="上传成功">{{content.successCount}}</el-descriptions-item>
        <el-descriptions-item label="上传失败">{{content.errorCount}}</el-descriptions-item>
        <el-descriptions-item label="查询返回结果">
          <el-row>
            <el-card class="box-card" v-for="(item, index) in lengthList" :key="index">
              <div slot="header" class="clearfix">
                <el-row type="flex" justify="space-between" align="middle">
                  <div>
                    <div>{{item.id}}</div>
                    <div style="color:red">{{codeFilter(item.errorsCode)}}</div>
                  </div>
                  <div style="float: right;">
                    <el-badge :value="item.errorsCode" class="item">
                      </el-badge>
                  </div>
                </el-row>
                
                <!-- <el-button style="float: right; padding: 3px 0" type="danger" circle>{{item.errorsCode}}</el-button> -->
                <!-- <el-button style="float: right; padding: 3px 0" type="text">{{item.errorsCode}}</el-button> -->
              </div>
              <div v-for="(items, indexs) in item.errorList" :key="indexs" class="text item">
                {{indexs}}. <span>{{items.errorFiled}}</span> <span style="color:red; margin-left:20px">{{codeFilter(items.errorCode)}}</span>
              </div>
            </el-card>
          ----------------
          <!-- <div>{{content.uploadXml}}</div> -->
          <div>
            {{content.downloadContent}}
          </div>
          </el-row>
          
        </el-descriptions-item>
      </el-descriptions>
  </div>
</template>
<script>
// import xmlLoad from '../../../../utils/xml'
export default {
  data() {
      return {
          content: {},
          lengthList:[]
      }
  },
  created() {
    console.log('this.$store.state.xml.content', this.$store.state.xml.content);
      this.content = this.$store.state.xml.content
      this.xmlFun()
    
  },
  methods:{
    codeFilter(code){
      var message=''
      switch (code) {
        case '100': 
          message='数据解析异常'
          break;
        case '110': 
          message='数据存储异常'
          break;
        case '102': 
          message='数据格式错误'
          break;
        case '1020': 
          message='数据格式异常,数据不能为空'
          break;
        case '1021': 
          message='数据格式异常,超出数据长度限制'
          break;
        case '1022': 
          message='数据格式异常,日期格式不正确'
          break;
        case '1023': 
          message='数据格式异常,代码格式不正确'
          break;
        case '1024': 
          message='数据格式异常,主体信息不能为空'
          break;
      
        default:
          break;
      }
      return message
    },
    xmlFun(){
      var parser=new DOMParser(); // 创建一个DOMParser
      var xmlDoc=parser.parseFromString(this.content.downloadContent,"text/xml"); // 把xml数据通过text/xml解析为dom数据

    //检索第一个 <ERRORS> 元素的数据  返回一个数组
    var  List = xmlDoc.getElementsByTagName("ERRORS")[0].childNodes
    for (let index = 0; index < List.length; index++) {
      const element = List[index];
      var ERROR_DETAILS = element.getElementsByTagName("ERROR_DETAILS")[0].childNodes // 获取ERROR_DETAILS的数组
       var errorList=[]
      //  子数组里面的值
      if(ERROR_DETAILS.length>0){
        for (let index = 0; index < ERROR_DETAILS.length; index++) {
          const element = ERROR_DETAILS[index];
          var errorObj={
            errorCode:element.getElementsByTagName("ERROR_CODE")[0].childNodes[0].nodeValue, // 获取ERROR_CODE的文本值
            errorFiled:element.getElementsByTagName("ERROR_FILED")[0].childNodes[0].nodeValue
          }
          errorList.push(errorObj)
        }
      }
      // 最终的数组对象值
      var obj={
        id: element.getElementsByTagName("ID")[0].childNodes[0].nodeValue,
        errorsCode: element.getElementsByTagName("ERRORS_CODE")[0].childNodes[0].nodeValue,
        errorList
      }
      this.lengthList.push(obj)
    }
    },
  }
}
</script>
<style scoped>
.padding-box{
  /* padding: 20px 10px; */
}
.app-content {
  background-color: #fff;
  padding: 20px;
}
.box-card{
  margin-bottom: 10px;
}
</style>
